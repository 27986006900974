import moment from 'moment';
import { useTranslate } from 'ra-core';
import React, { useState } from 'react';
import {
  DateField, FunctionField, RecordContextProvider, SimpleShowLayout, TextField, Title,
  useRecordContext
} from 'react-admin';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';

import BalanceIcon from '@mui/icons-material/Balance';
import BoltIcon from '@mui/icons-material/Bolt';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HardwareIcon from '@mui/icons-material/Hardware';
import HeightIcon from '@mui/icons-material/Height';
import InsightsIcon from '@mui/icons-material/Insights';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import ScaleIcon from '@mui/icons-material/Scale';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import {
  Avatar, Box, Button, Card, CardActionArea, CardContent, Checkbox, Chip, Collapse, Dialog,
  DialogActions, DialogContent, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
  ListSubheader, Paper, Skeleton, Slider, Stack, Typography
} from '@mui/material';
import { purple } from '@mui/material/colors';

import ActivityChart from '../components/ActivityChart';
import DimensionsRadarChart from '../components/DimensionsRadarChart';
import UserSessionsDisplay from '../components/UserSessionsDisplay';
import { User } from '../types';
import { getAsyncFetchData, postAsyncData, useFetchData } from '../utils/useAdminApi';
import UserRecommendations from './UserRecommendations';
import UsersProgramSessionsTimeline from './UsersSessionsTimeline';

const UserTitle = () => {
  const translate = useTranslate();
  const record = useRecordContext<User>();
  return record ? (
    <Title title={translate('resources.users.title', {
      id: record.patientId ? record.patientId : "",
    })} />
  ) : null;
};

const UserGeneralInfo = (props) => {
  const translate = useTranslate();
  const { data, loading } = props

  return (
    <Card
      key={0}
      variant="outlined"
      sx={{ mb: 2 }}
    >
      <CardContent
        sx={{ p: 0 }}
      >
        {
          (!data || loading) ?
            <Skeleton height={300} />
            :
            <SimpleShowLayout>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Avatar
                  sx={{ width: 56, height: 56 }}
                />
              </Box>
              <FunctionField
                label={translate(`resources.users.gender`)}
                render={
                  record => translate(`resources.users.${record.gender}`)
                }
              />
              {data.firstName &&
                <TextField label={translate(`resources.users.firstname`)} source="firstName" emptyText="-" />
              }
              {data.lastname &&
                <TextField label={translate(`resources.users.lastname`)} source="name" emptyText="-" />
              }
              {data.nickname &&
                <TextField label={translate(`resources.users.nickname`)} source="nickname" emptyText="-" />
              }
              {data.email &&
                <TextField label={translate(`resources.users.email`)} source="email" emptyText="-" />
              }
              {/* <TextField label={translate(`resources.users.phoneNumber`)} source="phoneNumber" emptyText="-" /> */}

              {process.env.REACT_APP_ID == 'alixmed' ?
              <DateField label={translate(`resources.users.monthOfBirth`)} source="dateOfBirth" emptyText="-" options={{ year: 'numeric', month: 'long' }} />
              :
              <DateField label={translate(`resources.users.dateOfBirth`)} source="dateOfBirth" emptyText="-" />
              }

            </SimpleShowLayout>
        }
      </CardContent>
    </Card>
  )
}


const UserMetricsInfo = (props) => {
  const { data, loading } = props

  return (
    <Card
      key={1}
      sx={{ mb: 2 }}
      variant="outlined"
    >
      <CardContent
        sx={{ p: 2 }}
      >
        {(!data || loading) ?

          <Skeleton height={40} />
          :
          <>
            {
              data.weight &&
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <ScaleIcon /> = {data.weight} kg
              </div>
            }
            {
              data.height &&
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <HeightIcon /> = {data.height} cm
              </div>
            }
            {
              (data.hrMin || data.hrMax) &&
              <>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <MonitorHeartIcon /> <span><sub>min</sub> = {data.hrMin} bpm</span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <MonitorHeartIcon /> <span><sub>max</sub> = {data.hrMax} bpm</span>
                </div>
              </>
            }
          </>
        }
      </CardContent>
    </Card>
  )
}

const UserSessions = () => {
  const { id } = useParams();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const sessionFetch = useFetchData("/adminApi/user/" + id + "/session")
  const exercisesFetch = useFetchData("/adminApi/exercise")

  if (sessionFetch.loading || exercisesFetch.loading) {
    return <Skeleton variant="rectangular" width={'100%'} height={60} />;
  }

  if (sessionFetch.error || exercisesFetch.error) {
    console.log('ERROR', sessionFetch.error, exercisesFetch.error);

    return <p>ERROR</p>;
  }

  let exercisesVariantsList = []
  if (typeof (exercisesFetch.data) !== "undefined") {
    exercisesVariantsList = exercisesFetch.data.reduce((object, item) => {
      item.variants.map((variant) => {
        object[variant.id] = item;
      });
      return object;
    }, {});
  }

  const sessions = sessionFetch.data

  console.log("exercisesVariantsList", exercisesVariantsList)
  return sessions && sessions.length ? (
    <Stack spacing={2}>
      <UserSessionsDisplay sessions={sessions.slice(0, 1)} showDetails={true} exercisesVariantsList={exercisesVariantsList} />
      <Stack
        direction="column"
        alignItems="center"
        spacing={1}>
        <Button sx={{ width: "300px" }} onClick={handleClick} variant="outlined" startIcon={<InsightsIcon />}>{translate(`resources.users.sessions.seeMore`)}</Button>
      </Stack>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"sm"}
        fullWidth={true}
      >
        <DialogContent>
          <Stack
            direction="column"
            justifyContent="center"
            spacing={1}>
            <UserSessionsDisplay sessions={sessions} showDetails={true} exercisesVariantsList={exercisesVariantsList} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>{translate(`resources.misc.close`)}</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  ) : null;
};

const UserProgramParams = (props) => {
  const { data, loading } = props
  const translate = useTranslate();

  if (loading || !data) {
    return <Paper sx={{ mb: 2, p: 2 }}>
      <Skeleton variant="rectangular" width={'100%'} height={60} />
    </Paper>;
  }

  if (!data.programParams) {
    return <></> // No program params
  }

  return (
    <Paper sx={{ mb: 2, p: 2 }}>
      <Typography variant="h6" component="h2" gutterBottom>
        {translate(`resources.users.programParams.title`)}
      </Typography>
      <List>
        {Object.entries(data.programParams).map(([key, value], index) => (
          <ListItem key={index} divider>
            <ListItemText
              primary={<Typography variant="subtitle1">
                {translate(`program.params.${key}`, { _: key })}
              </Typography>}
              secondary={<Typography variant="body2">
                {translate(`program.params.${value}`, { _: String(value) })}
              </Typography>}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  )
};

const UserActivityData = () => {
  const { id } = useParams();

  const { data, loading, error } = useFetchData("/adminApi/user/" + id + "/activity");
  console.log('ACTIVITY DATA', data);
  
  if (error) { return <p>ERROR</p>; }
  if (loading || !data) {
    return (
      <>
        <Skeleton variant="rectangular" width={'100%'} height={200} animation="wave" />
        <Skeleton variant="rectangular" width={'100%'} height={200} animation="wave" />
      </>
    );
  }
  data.sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf()); // Sort data by date
  const filteredData = (data && data?.length > 7) ? data?.slice(-7) : data;

  var goBackDays = 7;

  const activityChartData: any[] = [];
  const stepsChartData: any[] = [];
  const lastActivityWeekdays = {};
  const lastStepsWeekdays = {};

  for (var i = 0; i < goBackDays; i++) {
    let day = moment().startOf('day').subtract(i, 'day');

    let activityChartData = {
      ["dateOrigin"]: day.format('YYYY-MM-DD'),
      ["activity"]: 0,
      ["date"]: moment(day).locale('fr').format('ddd'),
      ["dateFull"]: moment(day).locale('fr').format('ddd DD MMM YY'),
    }
    lastActivityWeekdays[day.format('YYYY-MM-DD').toString()] = activityChartData;

    let stepsChartData = {
      ["dateOrigin"]: day.format('YYYY-MM-DD'),
      ["steps"]: 0,
      ["date"]: moment(day).locale('fr').format('ddd'),
      ["dateFull"]: moment(day).locale('fr').format('ddd DD MMM YY'),
    }
    lastStepsWeekdays[day.format('YYYY-MM-DD').toString()] = stepsChartData;
  }
  if (data && filteredData) {
    filteredData.map(activityData => {
      let key = moment(activityData.date).format('YYYY-MM-DD').toString();
      let hasActivityKey = lastActivityWeekdays.hasOwnProperty(key);
      if (hasActivityKey) {
        lastActivityWeekdays[key.toString()].activity = activityData.dataValues[1].value;
      }
      let hasStepsKey = lastStepsWeekdays.hasOwnProperty(key);
      if (hasStepsKey) {
        lastStepsWeekdays[key.toString()].steps = activityData.dataValues[0].value;
      }
    })

    for (const [key, value] of Object.entries(lastActivityWeekdays)) {
      activityChartData.push({
        ["dateOrigin"]: lastActivityWeekdays[key].dateOrigin,
        ["activity"]: Math.round(lastActivityWeekdays[key].activity * 60),
        ["date"]: lastActivityWeekdays[key].date,
        ["dateFull"]: lastActivityWeekdays[key].dateFull,
      });
    }

    for (const [key, value] of Object.entries(lastStepsWeekdays)) {
      stepsChartData.push({
        ["dateOrigin"]: lastStepsWeekdays[key].dateOrigin,
        ["steps"]: lastStepsWeekdays[key].steps,
        ["date"]: lastStepsWeekdays[key].date,
        ["dateFull"]: lastStepsWeekdays[key].dateFull,
      });
    }
  }

  return data ? (
    <Stack spacing={2} mt={2}>
      <Card>
        <CardContent>
          <ActivityChart data={activityChartData.reverse()} type={"activity"} />
          <ActivityChart data={stepsChartData.reverse()} type={"steps"} />
        </CardContent>
      </Card>
    </Stack>
  ) : null;
};

const UserDimensions = (props) => {
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const { data, loading } = props

  const dimensions = [
    { id: "balance", name: translate(`resources.modules.balance`), icon: <BalanceIcon color="success" /> },
    { id: "endurance", name: translate(`resources.modules.endurance`), icon: <DirectionsRunIcon sx={{ color: purple[500] }} />, color: "success" },
    { id: "power", name: translate(`resources.modules.power`), icon: <BoltIcon color="warning" />, },
    { id: "resistance", name: translate(`resources.modules.resistance`), icon: <HardwareIcon color="error" /> },
  ];

  const levels = {
    0: { name: translate(`resources.modules.low`), color: "primary" },
    1: { name: translate(`resources.modules.medium`), color: "info" },
    2: { name: translate(`resources.modules.high`), color: "warning" },
    3: { name: translate(`resources.modules.veryHigh`), color: "error" }
  };

  const DimensionLevel = (props) => {
    switch (props.level) {
      case 0:
      case 1:
      case 2:
      case 3:
        return (
          <Chip
            label={levels[props.level].name}
            color={levels[props.level].color}
            sx={{ ml: 1 }}
          />
        );
      default:
        return (
          <Chip
            label={translate(`resources.misc.none`)}
            sx={{ ml: 1 }}
          />
        );
    }
  };

  const chartData = dimensions.map((dimension, i) => {
    return {
      "name": dimension.name,
      "value": data && dimension.id in data ? data[dimension.id] : 0
    }
  });



  return (
    <>
      <Card
        sx={{ mb: 2, mt: 2 }}
        variant="outlined"
      >
        <CardActionArea onClick={handleClick}>
          <CardContent
            sx={{ p: 2 }}
          >
            <DimensionsRadarChart data={chartData} />
          </CardContent>
        </CardActionArea>
      </Card>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="Dimensions"
        maxWidth={"lg"}
        fullWidth={true}
      >
        <DialogContent>
          <Grid container spacing={2}>
            {
              dimensions.map((dimension, i) => {
                return (
                  <Grid sm={6} item>
                    <Paper sx={{ p: 2 }}>
                      <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}>
                        {dimension.icon}&nbsp;
                        <Typography>
                          {dimension.name}&nbsp;
                        </Typography>
                        {
                          (!data || loading) ?
                            <Skeleton />
                            :
                            <DimensionLevel level={data[dimension.id + "Level"]} />
                        }
                      </div>
                      <Grid container spacing={4} sx={{ mt: 2 }}>
                        <Grid sm={6} item>
                          <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center">
                            <MonitorHeartIcon />
                            {
                              (!data || loading) ?
                                <Skeleton />
                                :
                                data[dimension.id + "CardiacFeedback"] ?
                                  <Slider
                                    marks
                                    min={-2}
                                    max={2}
                                    defaultValue={data[dimension.id + "CardiacFeedback"]}
                                    valueLabelDisplay="on"
                                    color="secondary"
                                    size="small"
                                    disabled
                                  />
                                  :
                                  <Typography>
                                    {translate('resources.misc.noData')}
                                  </Typography>
                            }
                          </Stack>
                        </Grid>
                        <Grid sm={6} item>
                          <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center">
                            <ThumbUpAltIcon />
                            {
                              (!data || loading) ?
                                <Skeleton />
                                :
                                data[dimension.id + "UserFeedback"] ?
                                  <Slider
                                    marks
                                    min={-2}
                                    max={3}
                                    defaultValue={data[dimension.id + "UserFeedback"]}
                                    valueLabelDisplay={data[dimension.id + "UserFeedback"] == null ? "off" : "on"}
                                    color="secondary"
                                    size="small"
                                    disabled
                                  />
                                  :
                                  <Typography>
                                    {translate('resources.misc.noData')}
                                  </Typography>
                            }
                          </Stack>
                        </Grid>
                        <Grid sm={12} item>
                          <Stack spacing={2} direction="row" sx={{ mb: 0 }} alignItems="center">
                            <FitnessCenterIcon />
                            {
                              (!data || loading) ?
                                <Skeleton />
                                :
                                data[dimension.id] ?
                                  <Slider
                                    marks
                                    min={0}
                                    max={10}
                                    defaultValue={data[dimension.id]}
                                    valueLabelDisplay="on"
                                    color="secondary"
                                    size="small"
                                    disabled
                                  />
                                  :
                                  <Typography>
                                    {translate('resources.misc.noData')}
                                  </Typography>
                            }
                          </Stack>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                )
              })
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} autoFocus>{translate(`resources.misc.close`)}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const UserEventLogs = () => {
  const translate = useTranslate();
  const { id } = useParams();

  const { data, loading, error } = useFetchData("/adminApi/user/" + id + "/eventLogs")
  if (error) { return <p>ERROR</p>; }
  if (loading || !data) {
    return (
      <>
        <Typography component="div" variant="body1">
          <Skeleton />
        </Typography>
        <Typography component="div" variant="body1">
          <Skeleton />
        </Typography>
        <Typography component="div" variant="body1">
          <Skeleton />
        </Typography>
      </>
    );
  }

  const logsIcons = {
    "program.quit": <ExitToAppIcon />,
    "program.join": <CardMembershipIcon />,
    "program.patientIdChange": <ChangeCircleIcon />
  }

  const UserEventLogsWithCollapsibleInformation = (props) => {
    const { eventLog } = props

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
      setOpen(!open);
    };

    return (
      <>
        <ListItemButton
          onClick={handleClick}
          key={eventLog.id}
        >
          <ListItemIcon>
            <Avatar>
              {
                logsIcons[eventLog.eventType] ?
                  logsIcons[eventLog.eventType]
                  : null
              }
            </Avatar>
          </ListItemIcon>
          <ListItemText
            primary={eventLog.title}
            secondary={moment(eventLog.timestamp).locale('fr').format("ddd DD MMM YY - HH:mm:ss")}
          />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem sx={{ pl: 4 }}>
              <ListItemText primary={
                <Paper style={{ padding: '20px', backgroundColor: '#f5f5f5' }}>
                  <ReactJson src={eventLog} theme="rjv-default" collapsed={false} />
                </Paper>

              } />
            </ListItem>
          </List>
        </Collapse>

      </>
    )
  }

  return data ? (
    <List
      sx={{ width: '100%', mb: 2 }}
      component={Card}
      subheader={
        <ListSubheader>
          {translate(`resources.users.eventLogList`)}
        </ListSubheader>
      }
    >
      {data.map((row) => (
        <UserEventLogsWithCollapsibleInformation eventLog={row} />
      ))}
    </List>
  ) : null;
};

export const UserShow = (props) => {
  const { id } = useParams();
  const { data, loading, error } = useFetchData("/adminApi/user/" + id)

  console.log('USER SHOW DATA', data);

  return (
    <RecordContextProvider value={data}>
      <div>
        <UserTitle />
        <Grid
          container
          spacing={2}
          sx={{ mt: 2 }}
        >
          <Grid sm={12} md={process.env.REACT_APP_ID == 'alixmed' ? 4 : 3} item>
            <UserGeneralInfo data={data} loading={loading} />
            <UserMetricsInfo data={data} loading={loading} />
            <UserProgramParams data={data} loading={loading} />
            {process.env.REACT_APP_ID == 'alixmed' ?
            null // No recommendation component for Alix MED.
            :
            <UserRecommendations userRecommendations={data ? data.recommendations : undefined} userId={data ? data.id : undefined}/>
            }
            {process.env.REACT_APP_ID == 'alixmed' ?
            null // No dimensions component for Alix MED.
            :
            <UserDimensions data={data} loading={loading} />
            }
            {process.env.REACT_APP_ID == 'alixmed' ?
            <UserEventLogs />
            :
            null
            }
          </Grid>
          <Grid sm={12} md={process.env.REACT_APP_ID == 'alixmed' ? 8 : 4} item>
            {(data && data.sessionsByDate) &&
              <UsersProgramSessionsTimeline sessions={data.sessionsByDate} />
            }
            <UserSessions />
            <UserActivityData />
          </Grid>
          {process.env.REACT_APP_ID == 'alixmed' ?
          null
          :
          <Grid sm={12} md={5} item>
            <UserEventLogs />
          </Grid>
          }
        </Grid>
      </div>
    </RecordContextProvider>);
};

export default UserShow;
