/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_mobile_analytics_app_id": "eaebe8cb103b40c69e2cbc809f319a61",
    "aws_mobile_analytics_app_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://nvxi5wbztnbwbgrkehjikyzhcm.appsync-api.eu-west-3.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-3",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "alixConnectBrokerApi",
            "endpoint": "https://1kf9a360we.execute-api.eu-west-3.amazonaws.com/dev",
            "region": "eu-west-3"
        },
        {
            "name": "alixRestApi",
            "endpoint": "https://40sg1i7z77.execute-api.eu-west-3.amazonaws.com/dev",
            "region": "eu-west-3"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-3:4fa9daf0-5455-4310-95a5-4fce3b8494fa",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_mwBmBDt92",
    "aws_user_pools_web_client_id": "o6o67ornubjp03aojloam6c6h",
    "oauth": {
        "domain": "alix-dev.auth.eu-west-3.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://d1j0o06joxn555.cloudfront.net/,https://store.xecurify.com/moas/broker/login/jwt/callback/21702/alix/,localhost:3000/,alix://",
        "redirectSignOut": "https://d1j0o06joxn555.couldfront.net/logout/,localhost:3000/,alix://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "alix-gp-hosting-bucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-3",
    "aws_content_delivery_url": "https://dh44ctibien0j.cloudfront.net",
    "aws_dynamodb_all_tables_region": "eu-west-3",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "amplifyIdentityBrokerClientsTable-dev",
            "region": "eu-west-3"
        },
        {
            "tableName": "amplifyIdentityBrokerCodesTable-dev",
            "region": "eu-west-3"
        }
    ],
    "aws_user_files_s3_bucket": "alixd281fee019f84feeba00ed89ded333a093348-dev",
    "aws_user_files_s3_bucket_region": "eu-west-3"
};


export default awsmobile;
